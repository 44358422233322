import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Menu, Button, Icon } from 'antd';
import { menuData } from '../../common/js/menu';

import './style.less';

import imgLogo from '../../common/images/logo.png';

@withRouter
class Header extends Component {
    constructor(props) {
        super(props);
        // console.log(this.props);
        this.state = {
            current: this.props.location.pathname,
        }
    }

    handleClick = e => {
        console.log('click ', e);
        this.setState({ current: e.key,collapsed: false });
        const arr = menuData.filter((v, i) => i.toString() === e.key);
        if (arr && arr.length > 0) {           
            this.props.history.push(arr[0].path)           
        }

    };
    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const { collapsed, current } = this.state;
        return <div className='header'>
            <div className='content container'>
                <div className='logo'><img src={imgLogo} alt='菜小秘' /></div>
                <div className='menu'>
                    <Button type="primary" onClick={this.toggleCollapsed} className="navbar-toggle">
                        <Icon type="bars" />
                    </Button>

                    <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[current]}
                        className="menu-pc"
                        onClick={this.handleClick}
                    >
                        {menuData.map((v, i) =>
                            <Menu.Item key={v.path} disabled={v.disabled} ><Link to={v.path}>{v.name}</Link></Menu.Item>
                        )}
                    </Menu>
                </div>
            </div>

            {collapsed ?
                <Menu
                    mode="inline"
                    defaultSelectedKeys={[this.state.current]}
                    className="menu-mobile"
                    onClick={this.handleClick}
                >
                    {menuData.map((v, i) =>
                        <Menu.Item key={v.path} disabled={v.disabled}  ><Link to={v.path}>{v.name}</Link></Menu.Item>
                    )}
                </Menu>
                : null
            }

        </div>

    }

}

export default Header;