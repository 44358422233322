
export function judgeBrand(sUserAgent) {
    var isIphone = /iphone/.test(sUserAgent);
    var isHuawei = /huawei/.test(sUserAgent);
    var isHonor = /honor/.test(sUserAgent);
    var isOppo = /oppo/.test(sUserAgent);
    var isOppoR15 = /pacm00/.test(sUserAgent);
    var isVivo = /vivo/.test(sUserAgent);
    var isXiaomi = /mi /.test(sUserAgent);
    var isXiaomi2s = /mix /.test(sUserAgent);
    var isRedmi = /redmi/.test(sUserAgent);
    var isSamsung = /sm-/.test(sUserAgent);    
    if (isIphone) {
        return 'iphone';
    } else if (isHuawei || isHonor) {
        return 'huawei';
    } else if (isOppo || isOppoR15) {
        return 'oppo';
    } else if (isVivo) {
        return 'vivo';
    } else if (isXiaomi || isRedmi || isXiaomi2s) {
        return 'xiaomi';
    } else if (isSamsung) {
        return 'samsung';
    } else {
        return 'pc';
    }
}
export function isWeChat() {
    var ua = navigator.userAgent.toLowerCase();
    return (/micromessenger/.test(ua)) ? true : false;
}


/**判断是否是手机号**/
export function isPhoneNumber(tel) {
    var reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    return reg.test(tel);
}