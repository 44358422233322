import * as actionTypes from '../constant/download';

//初始状态
const initState = {
    cxm: {},//菜小秘大前端
    pos: {},//安卓版收银端
    mobilePos: {},//手机APP收银端
    mobileStall: {},//手机APP档主端
}
//reducer
export function download(state = initState, action) {
    switch (action.type) {
        case actionTypes.DOWNLOAD_CXM:
            return { ...state, cxm: { ...action.payload }, };
        case actionTypes.DOWNLOAD_POS:
            return { ...state, pos: { ...action.payload }, };
        default:
            return state;
    }
}