import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import './team2.less';
// import Header from '../../component/header/index';
// import Footer from '../../component/footer/index';
// import imgBanner from '../../common/images/banner.png';
// import Title from '../../component/title/index';

class Team2 extends Component {

    render() {
        const { name, img, img2, title, intro } = this.props;
        return <div className='team2'>
            {img2 ? <img className="img2" src={img2} alt='' /> : null}
            <div className={`${img2 ? 'img2-content' : ''} content`}>
                <img className="img" src={img} alt='' />
                <div className="name">{name}</div>
                <div className="title">{title}</div>
                <div className="intro" dangerouslySetInnerHTML={{ __html: intro }} ></div>
            </div>
        </div>

    }

}

export default Team2;