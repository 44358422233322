import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import CSSTransitionGroup from 'react-addons-css-transition-group';
import CarouselNav from './sub/nav.js';
import CarouselNav2 from './sub/nav2.js';
// import ButtonGroup from './sub/button.js';
import CarouselImage from './sub/images.js'
import img1 from '../../common/images/home/image1.png';
import img2 from '../../common/images/home/image2.png';
import img3 from '../../common/images/home/image3.png';
import img4 from '../../common/images/home/image4.png';

import './style.less'
/**
 * 轮播图组件
 */
export default class Carousel extends Component {
    constructor(...args) {
        super(...args);
        /* 存放图片地址及当前展示的图片索引 */
        this.state = {
            imageSrc: [
                img1,
                img2,
                img3,
                img4
            ],
            currentIndex: 0,
            height: '6rem',
            // title: ['【开单管理难】', '【销售码单难】',],
            // content: ['收银、销售经理手机协同开单，销售业绩实时统计', '收银开票效率up，规避错漏单，开单工作高效简单',]
        };
        /* 定时器引用 */
        this.timer = null;

        /* 绑定事件中this */
        this.prevImage = this.prevImage.bind(this);
        this.nextImage = this.nextImage.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this._updateIndex = this._updateIndex.bind(this);
    }

	/**
	 * 组件加载完毕后，图片自动播放
	 */
    componentDidMount() {
        this.timer = setInterval(
            () => {
                this.setState({
                    currentIndex: (this.state.currentIndex + 1) % this.state.imageSrc.length
                });
            },
            5000
        );

        //图片被缩放后会压缩，所以求个等比缩放的高度赋值给nva2
        const carouselImg = document.querySelector("li.carousel-image-item img");
        const height = carouselImg.clientWidth / (1056 / 600);
        this.setState({ height });
        // console.log(carouselImg.clientHeight,carouselImg.clientWidth,carouselImg.scrollHeight,height)
        // this.nav2
    }

	/**
	 * 组件卸载时，清理定时器
	 */
    componentWillUnmount() {
        this.timer && clearInterval(this.timer);
    }

	/**
	 * 展示前一张图片
	 */
    prevImage() {
        var currentIndex = this.state.currentIndex;
        var len = this.state.imageSrc.length;
        /* 计算下一张图片索引 */
        currentIndex = (currentIndex - 1) >= 0 ?
            (currentIndex - 1) % len : len - 1;
        /* 调用更新函数，更新当前显示的图片，并刷新定时器 */
        this._updateIndex(currentIndex, len);
    }

	/**
	 * 展示下一张图片
	 */
    nextImage() {
        var currentIndex = this.state.currentIndex;
        var len = this.state.imageSrc.length;
        /* 计算下一张图片索引 */
        currentIndex = (currentIndex + 1) % len;
        /* 调用更新函数，更新当前显示的图片，并刷新定时器 */
        this._updateIndex(currentIndex, len);
    }

	/**
	 * 展示选中索引图片
	 * @param  {number} index 索引值
	 */
    selectImage(index) {
        var len = this.state.imageSrc.length;
        this._updateIndex(index, len);
    }

	/**
	 * 工具函数，用于更新state，以及刷新定时器
	 * @param  {number} index 将要展示图片的索引
	 * @param  {number} len   展示图片总张数
	 * @param  {number} delay 动画持续时间
	 */
    _updateIndex(index, len, delay = 4000) {
        /* 清除定时器 */
        this.timer && clearInterval(this.timer);
        /* 设置当前展示图片 */
        this.setState({
            currentIndex: index
        });
        /* 打开定时器 */
        this.timer = setInterval(
            () => {
                var currentIndex = this.state.currentIndex;
                this.setState({
                    currentIndex: (currentIndex + 1) % len
                });
            },
            delay
        );
    }

    render() {
        const { imageSrc, height } = this.state;
        return (
            <div className="my-carousel">
                {/* <img src={img1} /> */}
                <CarouselNav
                    carouselNavItems={imageSrc}
                    currentIndex={this.state.currentIndex}
                    selectImage={this.selectImage}
                />
                <CarouselImage
                    imageSrc={imageSrc}
                    // title={title}
                    // content={content}
                    currentIndex={this.state.currentIndex}
                    enterDelay={1000}
                    leaveDelay={1500}
                    component={"li"}
                    name={"carousel-image-item"}
                />
                <CarouselNav2
                    // ref={(mw) => { this.domMw = mw; }}
                    // ref={(nav2 => { this.nav2 = nav2 })}
                    // ref={this.nav2}
                    height={height}
                    carouselNavItems={imageSrc}
                    currentIndex={this.state.currentIndex}
                    selectImage={this.selectImage}
                />
                {/* <ButtonGroup
                    prevImage={this.prevImage}
                    nextImage={this.nextImage}
                /> */}
            </div>
        );
    }
}
