import React from 'react';
import PropTypes from 'prop-types';
import './role.less'

/* 定义参数类型 */
const propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
}

/* 轮播图片组件 */
function Role(props) {
    let { title, img } = props;

    return (
        <div className="my-role">
            <div className="img"><img src={img} alt='' /></div>
            <div className="title">{title}</div>
        </div>
    );
}

Role.propTypes = propTypes;

export default Role;