import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { BackTop } from 'antd';
import { Route, Switch } from 'react-router-dom';
import './style.less';
import Header from '../../component/header/index';
import Footer from '../../component/footer/index';
import Home from '../home/index';
import Product from '../product/index';
import Solution from '../solution/index';
import Client from '../client/index';
import About from '../about/index';
import Download from '../download/index';

// import AppDownload from '../../component/app-download/index';
// import AppDownload2 from '../../component/app-download2/index';
import AppDownload3 from '../../component/app-download3/index';
// import { menuData } from '../../common/js/menu';

// @withRouter
class Layout extends Component {

    componentDidMount() {

    }

    render() {
        // const { pathname } = this.props.location;
        // menuData.filter(v => v.path === pathname)
        // const arr = menuData.filter(v => v.path === pathname);
        // if (arr && arr.length > 0) {
        //     document.title = document.title + ' - ' + arr[0].name;
        // }

        return <div className='layout'>
            <Header />
            <div className='content'>
                <Switch>
                    <Route exact path='/' component={Home}></Route>
                    <Route exact path='/home' component={Home}></Route>
                    <Route exact path='/product' component={Product}></Route>
                    <Route exact path='/solution' component={Solution}></Route>
                    <Route exact path='/client' component={Client}></Route>
                    <Route exact path='/about' component={About}></Route>
                    <Route exact path='/download' component={Download}></Route>
                </Switch>
            </div>
            <Footer />
            {/* <AppDownload /> */}
            {/* <AppDownload2 /> */}
            <AppDownload3 />
            <BackTop />
        </div>

    }

}

export default Layout;