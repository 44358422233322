import React, { Component } from 'react';
import imgBanner from '../../common/images/about/about-banner.png';
import imgFaZhan2 from '../../common/images/about/fazhan2.png';
import imgFaZhan3 from '../../common/images/about/fazhan3.png';
import imgFaZhan4 from '../../common/images/about/fazhan4.png';
import imgPicture2 from '../../common/images/about/picture2.png';
import imgPicture3 from '../../common/images/about/picture3.png';
import imgCulture from '../../common/images/about/culture.png';
import imgPicture6 from '../../common/images/about/picture6.png';
import imgPicture7 from '../../common/images/about/picture7.png';
import imgPicture8 from '../../common/images/about/picture8.png';
import imgPicture9 from '../../common/images/about/picture9.png';
import imgPicture13 from '../../common/images/about/picture13.png';
import imgPicture14 from '../../common/images/about/picture14.png';

import Title from '../../component/title/index';
import Title2 from '../../component/title2/index';
// import Team from './index/team';
import Team2 from './index/team2';
import './style.less';

// @withRouter
class About extends Component {
  render() {
    // const layout4 = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
    // const layout6 = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
    return (
      <div className='about'>
        <div className='banner'>
          <img src={imgBanner} alt='菜小秘' />
        </div>
        <div className='content2'>
          <Title2>公司简介</Title2>
          <div className='container'>
            <p>
              武汉智易农批科技有限公司成立于2018年，是一家致力于中国农贸流通信息化、智能化的企业。我们聚焦中国农贸流通领域，提供专业高效的行业解决方案，以及大数据和供应链金融服务，持续为客户创造最大价值，让我们农批人生意更简单、高效。
            </p>
            <p>
              公司旗下菜小秘为果蔬农批商行日常经营管理提供信息化解决方案，针对行业痛点，提供多角色协同、软硬件结合、一体化解决方案，帮助经营户降本增效、赊欠风控、高效获客，助力农批经营。
            </p>
          </div>
        </div>
        <div className='content3'>
          <Title>发展历程</Title>
          <div className='container'>
            <div>
              <div className='c3-img'>
                <img src={imgFaZhan2} alt='' />
              </div>
              <div className='c3-date'>
                <span>2018</span>
                <span>2019</span>
              </div>
              <div className='c3-text'>
                <div className='c3-text-1'>打磨</div>
                <div className='c3-text-2'>
                  <div>
                    <span className='c3-text-2-date'>2018.03</span>
                    <span>智易农批科技有限公司正式成立</span>
                  </div>
                  <div>
                    <span className='c3-text-2-date'>2018.10</span>
                    <span>旗下产品菜小秘正式上线商用，完成天使轮融资</span>
                  </div>
                  <div>
                    <span className='c3-text-2-date'>2019.08</span>
                    <span>获智慧城市创新创业“华中赛区优秀项目"</span>
                  </div>
                </div>
                <div className='c3-text-3'></div>
              </div>
            </div>
            <div>
              <div className='c3-text'>
                <div className='c3-text-1'>升华</div>
                <div className='c3-text-2'>
                  <div>
                    <span className='c3-text-2-date'>2020.11</span>
                    <span>获“瞪羚企业”认定</span>
                  </div>
                  <div>
                    <span className='c3-text-2-date'>2020.12</span>
                    <span>使用客户数突破5000+</span>
                  </div>
                  <div>
                    <span className='c3-text-2-date'>2020.12</span>
                    <span>获“国家高新技术企业”认定</span>
                  </div>
                  <div>
                    <span className='c3-text-2-date'>2021.10</span>
                    <span>使用客户数突破15000+</span>
                  </div>
                </div>
                <div className='c3-text-3'></div>
              </div>
              <div className='c3-date'>
                <span>2020</span>
                <span>2021</span>
              </div>
              <div className='c3-img'>
                <img src={imgFaZhan3} alt='' />
              </div>
            </div>
            <div>
              <div className='c3-img'>
                <img src={imgFaZhan4} alt='' />
              </div>
              <div className='c3-date'>
                <span>2022</span>
                <span>2023</span>
              </div>
              <div className='c3-text'>
                <div className='c3-text-1'>突破</div>
                <div className='c3-text-2'>
                  <div>
                    <span className='c3-text-2-date'>2022.04</span>
                    <span>上线建行惠市宝平台支付业务，开始全国性支付业务推广</span>
                  </div>
                  <div>
                    <span className='c3-text-2-date'>2022.09</span>
                    <span>和支付宝合作上线赊欠确认和保理业务助力农批资金流转</span>
                  </div>
                  <div>
                    <span className='c3-text-2-date'>2023.01</span>
                    <span>使用客户数突破40000+</span>
                  </div>
                </div>
                <div className='c3-text-3'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='content4'>
          <Title>创始团队</Title>
          <div className='container'>
            <div className='team2-warp'>
              <Team2
                name='李东林'
                img={imgPicture3}
                title='联合创始人&CEO'
                intro='毕业于上海同济大学。36氪专栏作者。原ADP大中华区产品负责人；iCamp兼职投资合伙人；经验十足，15年B端产品管理、产品运营经验，是TO B SaaS，TO C移动互联网领域的连续创业者。'
              />
              <Team2
                name='张建林'
                img={imgPicture2}
                title='项目早期发起人'
                intro='湖北经济学院酒店管理专业，高级物流师职称。武汉市农业产业重点龙头企业—武汉兴农方信农业公司创办人、总经理；武汉蔬菜协会副会长；20年生鲜农产品流通行业经验。'
              />
              <Team2
                name='杨发强'
                img={imgPicture6}
                title='首席架构师'
                intro='华为及武汉烽火信息平台部技术架构师；曾参与多个行业SaaS的架构设计和微服务架构设计，对大数据处理有多年的经验积累。'
              />
            </div>
            <div className='team2-warp'>
              <Team2
                name='龚凡'
                img={imgPicture8}
                title='技术总监'
                intro='华中科技大学本硕；10年技术开发与项目管理经验，先后参与或主导过ToC电商系统和ToB政企系统的建设与交付，积累了丰富的经验。'
              />
              <Team2
                name='陈明'
                img={imgPicture13}
                title='财务负责人'
                intro='武汉大学财务专业本科；大型国企多年财务管理经验，珠海基金财务部总经理，CPA注册会计师，CFA特许金融分析师。'
              />
              <Team2
                name='唐如孙'
                img={imgPicture14}
                title='大数据服务专家'
                intro='纽约州立大学石溪分校计算机硕士；10+年华尔街投行、对冲基金工作经验；2014年回国创业；专注供应链数字化、自动化、区块链技术应用。'
              />
              <Team2
                name='艾文'
                img={imgPicture7}
                title='客户成功总监'
                intro='百度高级产品经理；中纺智能制造E系统产品负责人；淘纱网撮合交易平台产品总监；对B2B交易环节的分级会员体系、中心仓储管理、物流配送等丰富的产品设计经验。'
              />
            </div>
          </div>
        </div>
        <div className='content5'>
          <div className='c5-1'>企业文化</div>
          <div className='c5-2'>让农批更简单</div>
          <div className='c5-3'>帮助每一个农批人实现数字化转型</div>
          <div className='c5-4'>
            6年来，武汉智易农批科技有限公司（简称菜小秘）利用创新农贸理念和领先技术架构，以大数据、云计算及供应链金融为基，专注农批行业发展，旨在构建互联网+农批产业新生态。菜小秘在农批领域为商行提供多种不同形态场景解决方案，操盘过亿现金流水农批项目，目前与北京新发地批发市场、武汉四季美批发市场、上海虹桥批发市场、深圳海吉星批发市场等商行达成战略合作，帮助50000+农批人实现互联网转型升级。
          </div>
          <div className='c5-img'>
            <img src={imgCulture} alt='' />
          </div>
        </div>
      </div>
    );
  }
}

export default About;
