import React from 'react'

class Error extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }
    componentDidCatch(err, info) {
        console.log(err, info)
        this.setState({ hasError: true })
    }
    render() {
        return this.state.hasError ?
            (<div>错误页面！</div>)
            :
            this.props.children
    }
}
export default Error