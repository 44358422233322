import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import imgBanner from '../../common/images/solution/solution-banner.png';
import imgUser1 from '../../common/images/solution/user1.png';
import img1 from '../../common/images/solution/img1.png';
import imgUser2 from '../../common/images/solution/user2.png';
import img2 from '../../common/images/solution/img2.png';
import imgUser3 from '../../common/images/solution/user3.png';
import img3 from '../../common/images/solution/img3.png';
import imgUser4 from '../../common/images/solution/user4.png';
import img4 from '../../common/images/solution/img4.png';
import imgUser5 from '../../common/images/solution/user5.png';
import img5 from '../../common/images/solution/img5.png';
import imgUser6 from '../../common/images/solution/user6.png';
import img6 from '../../common/images/solution/img6.png';
import MySolution from './index/mySolution';

import './style.less';

const data = [
    { title: '销售对账更轻松', userImg: imgUser1, img: img1, content: '我们每天货量大，销售人员多，账目统计工作太大了，进度慢，自从有了菜小秘，账本一键查看，都有时间可以生二胎了 &nbsp;&nbsp;&nbsp;&nbsp; --- 广发商行档主', leftText: '每天有大量货主和买家发货买货，工作人员分散各区域，统计数据需要挨个记录，人工处理，费时费力', rightText: '在手机上即可快速下单，一键完成信息收集。总部可实时统计数据，自动统计报表，所有往来账单一目了然' },
    { title: '蔬菜下单更容易', userImg: imgUser2, img: img2, content: '菜小秘很好的帮助我们解决繁琐的手工记账问题，大大提高效率了 &nbsp;&nbsp;&nbsp;&nbsp; --- 众禾鑫商行档主', leftText: '货主买主过来需要传统手工开单，有的时候来的较多，记的账本杂乱，每次核销账单都用很长时间，效率低还容易出现错单漏单的问题', rightText: '菜小秘直接用智能化方式一键下单，管理货批信息，自动结算金额，几秒完成有效开单及账目管理' },
    { title: '赊欠管理不再难', userImg: imgUser3, img: img3, content: '我们这行早起贪黑不怕，就怕不小心写错了单，特别是有些赊账情况出现错误，那损失就很大，自从有了菜小秘，赊账信息自动生成，清晰准确，账款催收更高效 &nbsp;&nbsp;&nbsp;&nbsp; --- 收达商行档主', leftText: '每天大量流水进出账，经常性赊账，万一错单、漏单，损失惨重', rightText: '财务数据自动核算，账单按时推送，支持业务线上线下多种缴费方式' },
    { title: '货主管理更简单', userImg: imgUser4, img: img4, content: '每天有大量货主、买主，客户信息管理难，从经营管理角度，无法判定商业风险，有了菜小秘，可以很好做到货、买主管理，判定一些风险    &nbsp;&nbsp;&nbsp;&nbsp;  --- 百菜园档主', leftText: '货、买主信息“不全、不新、不准”，找人不方便，如销售个人添加货、买主信息，一旦离职，资源全部带走', rightText: '通过菜小秘货、买主系统，在线管理往来客户信息，做好风险预判，打造营销闭环' },
    { title: '财务管理更清晰', userImg: imgUser5, img: img5, content: '以前账单、码单都是我一笔笔核实，为了避免错误，都是核实几遍，自从有了菜小秘，节省大量时间，直接生成报表，非常清晰 &nbsp;&nbsp;&nbsp;&nbsp; --- 华美商行财务', leftText: '账单杂乱多样，登记繁冗耗时，极易出现错漏、重复等系列问题，每一笔账单都代表现金流水，从而导致不必要损失', rightText: '通过菜小秘财务管理系统，随时查看账单、赊欠等信息。数字化财务，让经营一目了然' },
    { title: '库存管理更规范', userImg: imgUser6, img: img6, content: '以前货主来货清点需要自己去查帐，自从咱们有了菜小秘，点击批次管理，马上就能轻松统计收益情况 &nbsp;&nbsp;&nbsp;&nbsp; --- 绿健商行档主', leftText: '货主来货，需要手动记账，通过人工核算来查询每笔收益，耗时费力', rightText: '点击批次管理，一键录入货品信息，买货自动结算，轻松查询往来收益' },
];

// @withRouter
class Solution extends Component {

    render() {
        return <div className='solution'>
            <div className='banner'><img src={imgBanner} alt='菜小秘' /></div>
            <div className="content2">
                {
                    data.map((v, i) => <MySolution key={i} {...v} bgColor={i % 2 === 0 ? '#FFFFFF' : '#EFEFEF'}  />)
                }

            </div>
        </div>
    }
}

export default Solution;