// 后端api地址
let api_admin = 'https://stall.admin.caixm.cn/caixmAdmin/';
let api_comService = 'http://dev.api.caixm.cn/caixmAdmin/';

const NODE_ENV = process.env.REACT_APP_API; //process.env.NODE_ENV;
if (NODE_ENV === 'test') {
  //测试环境
  api_admin = 'http://test.api2x.caixm.cn/caixmAdmin/';
  api_comService = 'http://test.api2x.caixm.cn/cxm-cxmComServiceApi/';
} else if (NODE_ENV === 'preview') {
  //预发布
  api_admin = 'http://pre.api2x.caixm.cn/caixmAdmin/';
  api_comService = 'http://pre.api2x.caixm.cn/cxm-cxmComServiceApi/';
} else if (NODE_ENV === 'production') {
  //正式环境
  api_admin = 'https://stall.admin.caixm.cn/caixmAdmin/';
  api_comService = 'https://stall.mp.caixm.cn/cxm-cxmComServiceApi/';

  //如果是正式环境，需要从http:跳转到https://
  var protocolStr = document.location.protocol;
  var hostname = document.location.hostname;
  if (protocolStr === 'http:' && hostname !== 'localhost') {
    var url = window.location.href;
    window.location.href = 'https://' + url.substring(7, url.length);
  }
} else {
  //本地开发环境
  api_admin = 'http://dev.api.caixm.cn/caixmAdmin/';

  api_comService = 'http://dev.api.caixm.cn/cxm-cxmComServiceApi/';
}

export const API_ADMIN = api_admin; //后端接口
export const API_ComService = api_comService; //后端接口
