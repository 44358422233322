import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import './varieties.less'

/* 定义参数类型 */
const propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
}

/* 轮播图片组件 */
function Varieties(props) {
    const { title, img, img1, img2, img3, img4, title1, title2, title3, title4 } = props;

    return (
        <div className="my-varieties">
            <div><img src={img} alt='' /></div>
            <div className="title">{title}</div>
            <div><img className='sub-img' src={img1} alt='' /></div>
            <div className="subtitle">{title1}</div>
            <div><img className='sub-img' src={img2} alt='' /></div>
            <div className="subtitle">{title2}</div>
            <div><img className='sub-img' src={img3} alt='' /></div>
            <div className="subtitle">{title3}</div>
            <div><img className='sub-img' src={img4} alt='' /></div>
            <div className="subtitle">{title4}</div>
            <div className="more"><Icon type="ellipsis" /></div>
        </div>
    );
}

Varieties.propTypes = propTypes;

export default Varieties;