import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import wrong from './images/wrong2.jpg';
import './style.less'

@withRouter
class not404 extends React.Component {

    componentDidMount() {        
        document.getElementById("root").classList.add("body-bg");
    }

    componentWillUnmount(){
        document.getElementById("root").classList.remove("body-bg");
    }

    render() {
        // const { match } = this.props;
        return (
            <div className="not404">
                <div className="error-page">
                    <div className="errpr-img">
                        <img src={wrong} alt=""/>
                    </div>
                    <p>404 很抱歉!</p>
                    <p>没有找到您要访问的页面!</p>
                    <Link to='/'>返回到网站</Link>
                </div>
            </div>
        )
    }
}

export default not404;
