import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';

import imgLogoFooter from '../../common/images/logo-footer.png';
import imgQRcode from '../../common/images/QRcode.jpg';
import beianPicture from '../../common/images/home/beian.png';

import './style.less';


@withRouter
class Footer extends Component {

    render() {
        const { pathname } = this.props.location;
        let backgroundColor = '#EFEFEF';
        if (pathname === '/product' || pathname === '/solution' || pathname === '/about' || pathname === '/download') {
            backgroundColor = '#FFFFFF';
        }

        // xs={24} sm={24} md={12} lg={6} xl={6}         
        return <div className='footer' style={{ backgroundColor }}>
            <div className='content container'>
                <Row type="flex" justify="space-between" align="middle">
                    <Col xs={24} sm={24} md={12} lg={18} xl={18}>
                        {/* <Row type="flex" justify="center" align="middle" className='footer-left'>
                                <Col xs={24} sm={24} md={12} lg={18} xl={18}>
                                    <img src={imgLogoFooter} alt='菜小秘' className='imgLogoFooter' />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <div className="font16">客服热线：027-87523935</div>
                                    <div className="font16">公司地址：湖北省武汉市东湖高新区凌家山南路光谷企业天地4号楼2006-07</div>
                                </Col>
                            </Row> */}
                        <div className='footer-left'>
                            <div><img src={imgLogoFooter} alt='菜小秘' className='imgLogoFooter' /></div>
                            <div>
                                <div className="font16">联系方式：400-666-2155</div>
                                <div className="font16">公司地址：湖北省武汉市东湖高新技术开发区佳园路55号光谷创新港802</div>
                                <div className="font16">公司名称：武汉智易农批科技有限公司</div>
                                <div className="font16">
                                    <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer" alt="" >鄂ICP备16009208号-1</a>
                                    &nbsp;&nbsp;
                                    <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer" alt="" >鄂ICP备16009208号-3</a>
                                    &nbsp;&nbsp;
                                {/* <a href="http://www.gsxt.gov.cn/%7B5317864F3B36279DC801414C19EAA5D118C326737EA55693020F7BB26977CF06469D6EAB3A37D268C3B70DA64303E6EB1950A6FF6DDEDBAA19EFBECF7CC1BB312A0B2A0B2AA02A31100D2C0D2C0D2C0D2C7F5E43CDFCCD5887C8D6A83C699AD525651144A4B9A494B511F44EE500DB94C1213C1D3C1D3C1D-1581673670287%7D"
                                        target="_blank" rel="noopener noreferrer">执照公示</a> */}
                                    <a href="https://www.caixm.cn/%E6%AD%A6%E6%B1%89%E6%99%BA%E6%98%93%E5%86%9C%E6%89%B9%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%E6%8A%A5%E5%91%8A.pdf"
                                        target="_blank" rel="noopener noreferrer">执照公示</a>
                                </div>
                                <div className="font16">
                                    <a className='beianA' target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502005189" >
                                        <img className='beianImg' src={beianPicture} alt=""/>
                                        <p className='beianP'>鄂公网安备 42018502005189号</p>
                                    </a>
                                    &nbsp;&nbsp;
                                    <a className='beianA' target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502005190" >
                                        <img className='beianImg' src={beianPicture}  alt=""/>
                                        <p className='beianP'>鄂公网安备 42018502005190号</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <div className='footer-right'>
                            <div>
                                <div className="font11">菜小秘微信公众号</div>
                                <div className="font11">扫码关注更多详情</div>
                            </div>
                            <div><img src={imgQRcode} alt='菜小秘' className='imgQRcode' /></div>
                        </div>
                    </Col>
                </Row>
                {/* <div>
                    <img src={imgLogoFooter} alt='菜小秘' />
                </div>
                <div>
                    <div>客服热线：027-87523935</div>
                    <div>公司地址：湖北省武汉市东湖高新区凌家山南路光谷企业天地4号楼2006-07</div>
                </div>
                <div>
                    <div>菜小秘微信公众号</div>
                    <div>扫码关注更多详情</div>
                </div>
                <div>
                    <img src={imgQRcode} alt='菜小秘' />
                </div> */}
            </div>
        </div>

    }

}

export default Footer;