import React from 'react';
// import { Icon } from 'antd';
// import imgQRcode from '../../common/images/QRcode.jpg';
// import imgAppDownload from '../../common/images/app-download.png';
import imgQRcode3 from '../../common/images/QRcode3.png';
import imgIconWechatHover from '../../common/images/app-download3/icon-wechat-hover.png';
import imgIconTelHover from '../../common/images/app-download3/icon-tel-hover.png';

import './style.less'

/*  */
export default function () {

    return (
        <div className="app-download3">
            <div className="pc">
                <a href='tel:4006662155'>
                    <div className='icon-tel'></div>
                    <div className='icon-tel-hover'>
                        <img src={imgIconTelHover} alt='菜小秘' />
                    </div>
                </a>
                <div className='wechat'>
                    <div className='icon-wechat'></div>
                    <div className='icon-wechat-hover'>
                        <img src={imgIconWechatHover} alt='菜小秘' />
                    </div>
                </div>
            </div>
            <div className="mobile">
                <a href='tel:4006662155'>
                    <div></div>
                    {/* <img src={imgAppDownload} alt='菜小秘' /> */}
                </a>
                <div className='wechat'>
                    <div className='icon-wechat'></div>
                    <div className='qrcode3'>
                        <img src={imgQRcode3} alt='菜小秘' />
                    </div>
                </div>

            </div>
        </div>
    )
}
