import React, { Component } from 'react';

import './style.less';
class Title2 extends Component {
    render() {
        return <div className='container-title2'>
            {this.props.children}
        </div>
    }
}

export default Title2;