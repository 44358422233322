import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Inspector } from 'react-dev-inspector';
// import { connect } from 'react-redux';
// import qs from 'qs';
import not404 from './container/404/index';
import Layout from './container/layout/index';
// import Home from './container/home/index';
// import Product from './container/product/index';
import Help from './container/help/index';

// import './rem'//支持rem单位

import './app.less';

const isDev = ['local', 'development', 'test', 'preview'].includes(process.env.REACT_APP_API);
@withRouter
class App extends Component {

  render() {
    return <div className='app'>
      <Switch>        
        {/* <Route exact path='/home' component={Home}></Route>
        <Route exact path='/product' component={Product}></Route> */}
        <Route exact path='/help' component={Help}></Route>
        <Route path='/' component={Layout}></Route>
        <Route component={not404}></Route>
      </Switch>
      {isDev && (
        <Inspector
          keys={['control', 'shift', 'command', 'c']}
          disableLaunchEditor={true}
          onClickElement={({ codeInfo }) => {
            if (!codeInfo?.absolutePath) return;
            const { absolutePath, lineNumber, columnNumber } = codeInfo;
            window.open(`vscode://file/${absolutePath}:${lineNumber}:${columnNumber}`);
          }}
        >
          <div />
        </Inspector>
      )}
    </div>
  }
}

export default App;
