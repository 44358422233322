import * as actionTypes from '../constant/phone';
import * as req from '../request/phone';

/**
 * 手机电话号码
 */
export function sendPhone(params) {
    return async dispatch => {
        const res = await req.sendPhone(params);
        if (res) {
            dispatch({ type: actionTypes.PHONE_SAVE, payload: res })
        }
    }
}