import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, message } from 'antd';
import moment from 'moment';

import { getCxmData, getPosData, getMobilePosData, getMobileStallData } from '../../../state/action/download';

import './download.less';
const dateformat = 'YYYY-MM-DD';
@connect((state) => state.download, { getCxmData, getPosData, getMobilePosData, getMobileStallData })
class Download extends Component {
  constructor(props) {
    super(props);

    const u = window.navigator.userAgent;
    //钉钉手机浏览器 Mozilla/5.0 (Linux; U; Android 9; zh-CN; BLA-AL00 Build/HUAWEIBLA-AL00) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/57.0.2987.108 UCBrowser/11.9.4.974 UWS/2.13.2.90 Mobile Safari/537.36 AliApp(DingTalk/4.7.15) com.alibaba.android.rimet/12207179 Channel/227200 language/zh-CN
    // const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    const micromessenger = u.toLowerCase().match(/MicroMessenger/i);
    // console.log(micromessenger, typeof micromessenger)
    this.isWeiXin = micromessenger && micromessenger.toString() === 'micromessenger'; //如果返回true 则说明是weixin
    this.isQQ = u.match(/QQ\/[0-9]/i); //如果返回true 则说明是qq
    this.isMobile = /(iPhone|iPad|iPod|iOS|Android)/i.test(u); //手机版
    this.isMobileDing = /(DingTalk)/i.test(u); //手机版 钉钉

    this.imgDownload1 = require('../../../common/images/home/download1.png');
    this.imgDownload2 = require('../../../common/images/home/download2.png');
    this.imgDownload3 = require('../../../common/images/home/download3.png');
    this.imgDownload4 = require('../../../common/images/home/download4.png');
    if (this.props.source === 'download-page') {
      //如果页面来自下载页面，那么需要改变一些图片样式什么的
      this.imgDownload1 = require('../../../common/images/download/download1.png');
      this.imgDownload2 = require('../../../common/images/download/download2.jpeg');
      this.imgDownload3 = require('../../../common/images/download/download3.png');
      this.imgDownload4 = require('../../../common/images/download/download4.png');
    }
  }
  componentDidMount() {
    Promise.all([this.props.getPosData(), this.props.getCxmData()]);
  }

  handleDownload = (link) => {
    if (this.isWeiXin || this.isQQ || this.isMobileDing) {
      message.info('请在浏览器打开');
    } else {
      window.location.href = link;
    }
  };
  handleDownloadAPP = (link) => {
    if (this.isWeiXin || this.isQQ || this.isMobileDing) {
      message.info('请在浏览器打开');
    } else {
      const ua = navigator.userAgent.toLowerCase();
      const isIos = /(ios|ipad|iphone)/.test(ua) || ua.indexOf('macintosh') > -1;
      if (isIos) {
        window.location.href = 'https://apps.apple.com/cn/app/id1490901038';
      } else {
        window.location.href = link;
      }
    }
  };

  render() {
    const { layout6, pos, cxm } = this.props;
    let version = '';
    let cxmversion = '';
    let posLink = '';
    let appLink = '';
    let posUpdateTime = '';
    let appUpdateTime = '';

    if (pos.code === 200) {
      posLink = pos.data.data[0].link;
      version = pos.data.data[0].version;
      posUpdateTime = pos.data.data[0].updateTime || pos.data.data[0].createTime;
    }
    if (cxm.code === 200) {
      appLink = cxm.data.data[0].link;
      cxmversion = cxm.data.data[0].version;
      appUpdateTime = cxm.data.data[0].updateTime || cxm.data.data[0].createTime;
    }
    console.log(this.state, this.props);

    return (
      <div className='download'>
        <div className='container'>
          <Row gutter={20} type='flex' justify='space-between' align='middle'>
            <Col {...layout6}>
              <div className='c7'>
                <img className='c7-img' src={this.imgDownload2} alt='' />
                <div className='c7-title'>菜小秘平板端</div>
                <div className='c7-date'>{version}</div>
                <div className='c7-desc'>仅支持Android设备</div>
                <div className='c7-time'>
                  {posUpdateTime ? `更新时间${moment(posUpdateTime).format('YYYY年M月D日')}` : ''}
                </div>
                <div className='c7-btn'>
                  <Button type='primary' shape='round' size='large' onClick={() => this.handleDownload(posLink)}>
                    下 载
                  </Button>
                </div>
              </div>
            </Col>
            <Col {...layout6}>
              <div className='c7'>
                <img className='c7-img' src={this.imgDownload1} alt='' />
                <div className='c7-title'>菜小秘手机端</div>
                <div className='c7-date'>{cxmversion}</div>
                <div className='c7-desc'>支持Android/ios设备</div>
                <div className='c7-time'>
                  {appUpdateTime ? `更新时间${moment(appUpdateTime).format('YYYY年M月D日')}` : ''}
                </div>
                <div className='c7-btn'>
                  <Button type='primary' shape='round' size='large' disabled={false} onClick={() => this.handleDownloadAPP(appLink)}>
                    下载
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div className='developer'>
            开发者：武汉智易农批科技有限公司
            <a className='policy' target="_blank" href="./application-permissions.html">
              应用权限
            </a>
            <div className='divide'></div>
            <a className='policy' target="_blank" href="https://www.caixm.cn/privacy-agreement.html">
              隐私政策
            </a>
            <div className='divide'></div>
            <a className='policy' target="_blank" href="./#/product">
              功能介绍
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Download;
