
export const menuData = [
    {
        name: '首页',
        path: '/',
        disabled: false,
    },
    {
        name: '产品服务',
        path: '/product',
        disabled: false,
    },
    {
        name: '解决方案',
        path: '/solution',
        disabled: false,
    },
    {
        name: '客户案例',
        path: '/client',
        disabled: false,
    },
    // {
    //     name: '新闻中心',
    //     path: '/news',
    //     disabled: true,
    // },
    {
        name: '关于我们',
        path: '/about',
        disabled: false,
    },
    {
        name: '下载',
        path: '/download',
        disabled: false,
    },
];

